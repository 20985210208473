import React from 'react';
import {useController} from 'react-hook-form';
import RangeInput from 'components/common/RangeInput';
import {CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material';
import ShowTrack, {TrackPoint} from './ShowTrack';
import {SubEventType} from 'components/ListEventsPage/ListEventsPage';
import {gql, useQuery} from '@apollo/client';

const gqlGetStravaActivities = gql`
  query GetStravaActivities($slug: String!) {
    items: getStravaActivities(slug: $slug) {
      id
      name
      points {
        lat
        lng
        time
        alt
      }
    }
  }
`;

type ActivityType = {id: number; name: string; points: TrackPoint[]};

const StravaTrackForm = (props: StravaTrackFormProps) => {
  const {subEvent} = props;

  const [selected, setSelected] = React.useState<number>();
  const [data, setData] = React.useState<TrackPoint[]>([]);

  const {data: stravaData, loading} = useQuery<{items: ActivityType[]}>(gqlGetStravaActivities, {
    variables: {
      slug: subEvent.slug
    }
  });

  const {
    field: {value: track, onChange: setTrack}
  } = useController({name: 'track'});

  const [minValue, setMinValue] = React.useState<number>(0);
  const [maxValue, setMaxValue] = React.useState<number>(0);
  const [value, setValue] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (stravaData && stravaData.items.length > 0) {
      setSelected(stravaData.items[0].id);
      setTrack(stravaData.items[0]);
    }
  }, [stravaData]);

  const onChangeValue = (newValue: number[]) => {
    const size = data.length;
    const newTrack = [];
    for (let i = 0; i < size; i++) {
      if (newValue[0] <= data[i].time && newValue[1] >= data[i].time) {
        newTrack.push(data[i]);
      }
    }
    setValue(newValue);
    setTrack(newTrack);
  };

  React.useEffect(() => {
    const activity: ActivityType = stravaData?.items.find((item) => item.id === selected) as ActivityType;
    if (activity) {
      const points = activity.points;
      setTrack(points);
      setData(points);
      setMinValue(points[0].time);
      setMaxValue(points[points.length - 1].time);
      setValue([points[0].time, points[points.length - 1].time]);
    }
  }, [selected]);

  if (loading) {
    return <CircularProgress />;
  }

  if (stravaData?.items.length === 0) {
    return <div>No Strava Activities</div>;
  }

  const onChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(parseInt((event.target as HTMLInputElement).value));
  };

  return (
    <React.Fragment>
      <FormControl>
        <FormLabel id="activities-radio-buttons-group-label">Activities</FormLabel>
        <RadioGroup
          aria-labelledby="activities-radio-buttons-group-label"
          value={selected}
          onChange={onChangeActivity}
          name="activities-radio-buttons-group"
        >
          {(stravaData?.items || []).map((item) => (
            <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.name} />
          ))}
        </RadioGroup>
      </FormControl>
      <ShowTrack subEvent={subEvent} color="#ff0066" track={track} />
      <FormControl fullWidth margin="dense">
        <RangeInput min={minValue} max={maxValue} onChange={onChangeValue} value={value} />
      </FormControl>
    </React.Fragment>
  );
};

export default StravaTrackForm;

type StravaTrackFormProps = {
  subEvent: SubEventType;
};
