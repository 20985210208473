import React from 'react';
import {useNavigate} from 'react-router-dom';
import {gql, useQuery, useMutation} from '@apollo/client';

import CardFrame from '../common/CardFrame';
import {useParams} from 'react-router';
import {Avatar, Box, CircularProgress, FormControl, Grid, TextField, Typography, Button} from '@mui/material';
import {LockOutlined} from '@mui/icons-material';
import {FormContainer} from 'react-hook-form-mui';
import {TextInput} from 'components/common/Inputs';
import {toast} from 'react-toastify';

const GET_RECOVER_EMAIL = gql`
  query GetRecoverEmail($str: String!) {
    getRecoverEmail(str: $str)
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($str: String!, $password: String!) {
    resetPassword(str: $str, password: $password)
  }
`;

const ResetPasswordPage = () => {
  const [errors, setErrors] = React.useState<string>();
  const {str} = useParams();

  const {loading, data, error} = useQuery(GET_RECOVER_EMAIL, {
    variables: {
      str
    }
  });

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      toast.success('Your password was changed!');
      navigate('/passwordchanged');
    },
    onError: (error) => {
      const message = error.message ? error.message : 'Network error.';
      setErrors(message);
    }
  });

  const navigate = useNavigate();

  const onSubmit = ({password}: {password: string}) => {
    resetPassword({
      variables: {
        str,
        password
      }
    });
  };

  if (error) return <>{error.message}</>;

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Grid container justifyContent="center">
      <Grid item md={8} lg={6}>
        <CardFrame>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h4">
              Reset Your Password
            </Typography>
            <div>Create a new password for your account. If you have a problem resetting your password, please contact support.</div>
            <FormContainer onSuccess={onSubmit}>
              <TextField disabled fullWidth name="email" label="Email" value={data.getRecoverEmail} />
              <TextInput required fullWidth type="password" name="password" label="New Password" minLength={6} />
              <FormControl fullWidth sx={{mt: 3, mb: 2, alignItems: 'center'}}>
                <Button type="submit" variant="contained" sx={{width: 'fit-content'}}>
                  Submit
                </Button>
              </FormControl>
              <Typography>{errors}</Typography>
            </FormContainer>
          </Box>
        </CardFrame>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordPage;
