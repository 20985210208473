import React from 'react';
import {Outlet} from 'react-router';
import Header from '../common/Header';
import Footer from '../common/Footer';

// import MobileAppPage from '../Accounts/MobileAppPage'
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import HomeComponent from '../Home/HomeComponent';
import ListEventsPage from '../ListEventsPage/ListEventsPage';
import EventPage from 'components/Event/EventPage';
import {Container} from '@mui/material';
import ProfilePage from 'components/Accounts/Profile/ProfilePage';
import StravaProfilePage from 'components/Accounts/Profile/StravaProfilePage';
import Logout from 'components/Accounts/Logout';
import LoginPage from 'components/Accounts/LoginPage';
import {UserContextProvider} from 'components/providers/UserContext';
import SignUpPage from 'components/Accounts/SignUp';
import RegisteredPage from 'components/Accounts/RegisteredPage';
import RecoverPasswordPage from 'components/Accounts/RecoverPasswordPage';
import MyEventsPage from 'components/MyEventsPage/MyEventsPage';
import ContactsPage from 'components/Contacts/ContactsPage';
import ActivateAccount from 'components/Accounts/ActivateAccount';
import PasswordChangedPage from 'components/Accounts/PasswordChangedPage';
import ResetPasswordPage from 'components/Accounts/ResetPasswordPage';

// const HomeComponent = lazy(() => import(/* webpackChunkName: 'home' */ '../Home/HomeComponent'));
// const ListEventsPage = lazy(() => import(/* webpackChunkName: 'events' */ '../ListEventsPage/ListEventsPage'));
// const ContactsPage = lazy(() => import(/* webpackChunkName: 'contact' */ '../Contacts/ContactsPage'));
// const EventPage = lazy(() => import(/* webpackChunkName: 'info' */ '../Event/EventPage'));

// const Logout = lazy(() => import(/* webpackChunkName: 'logout' */ '../Accounts/Logout'));
// const LoginPage = lazy(() => import(/* webpackChunkName: 'login' */ '../Accounts/LoginPage'));
// const SignUpPage = lazy(() => import(/* webpackChunkName: 'signup' */ '../Accounts/SignUp'));
// const RegisteredPage = lazy(() => import(/* webpackChunkName: 'registered' */ '../Accounts/RegisteredPage'));
// const PasswordChangedPage = lazy(() => import(/* webpackChunkName: 'passwordChanged' */ '../Accounts/PasswordChangedPage'));
// const ProfileRouter = lazy(() => import(/* webpackChunkName: 'profile' */ './ProfileRouter'));
// const PrivacyPolicy = lazy(() => import(/* webpackChunkName: 'privacy' */ '../Legal/PrivacyPolicy'));
// const AccountRouter = lazy(() => import(/* webpackChunkName: 'account' */ './AccountRouter'));

// const MainRouter2 = () => {
//   return (
//     <div className="app-body">
//       <Header />
//       <div className="container main">
//         <Suspense fallback={<Loader />}>
//           <Routes>
//             <Route exact path="/" element={<HomeComponent />} />
//             <Route path="/events" element={<ListEventsPage />} />
//             <Route path="/contacts" element={<ContactsPage />} />
//             <Route path="/login" element={<LoginPage />} />
//             <Route path="/logout" element={<Logout />} />
//             <Route path="/signup" element={<SignUpPage />} />
//             <Route path="/registered" element={<RegisteredPage />} />
//             <Route path="/passwordchanged" element={<PasswordChangedPage />} />
//             <Route path="/profile/*" element={<ProfileRouter />} />
//             <Route path="/account/*" element={<AccountRouter />} />

//             {/* <Route path='/app' element={<MobileAppPage/>} /> */}
//             <Route path="/legal/privacy" element={<PrivacyPolicy />} />
//             <Route path="/:slug/*" element={<EventPage />} />
//           </Routes>
//         </Suspense>
//       </div>
//       <Footer />
//     </div>
//   );
// };

const Home = () => (
  <UserContextProvider>
    <div className="app-body">
      <Header />
      <Container maxWidth="lg" className="main">
        <Outlet />
      </Container>
      <Footer />
    </div>
  </UserContextProvider>
);

const MainRouter = () => {
  // Define routes accessible only to authenticated users
  const routes = [
    {
      path: '/',
      element: <Home />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: '/',
          element: <HomeComponent />
        },
        {
          path: '/events',
          element: <ListEventsPage />
        },
        {
          path: '/contacts',
          element: <ContactsPage />
        },
        {
          path: '/logout',
          element: <Logout />
        },
        {
          path: '/login',
          element: <LoginPage />
        },
        {
          path: '/signup',
          element: <SignUpPage />
        },
        {
          path: '/registered',
          element: <RegisteredPage />
        },
        {
          path: '/profile',
          element: <Outlet />,
          children: [
            {
              path: '/profile/',
              element: <ProfilePage />
            },
            {
              path: '/profile/strava',
              element: <StravaProfilePage />
            },
            {
              path: '/profile/myevents',
              element: <MyEventsPage />
            }
          ]
        },
        {
          path: '/account',
          element: <Outlet />,
          children: [
            {
              path: '/account/recover',
              element: <RecoverPasswordPage />
            },
            {
              path: '/account/reset/:str',
              element: <ResetPasswordPage />
            },
            {
              path: '/account/activate/:str',
              element: <ActivateAccount />
            }
            // {
            //   path: '/account',
            //   element: <MyEventsPage />
            // }
          ]
        },
        {
          path: '/passwordchanged',
          element: <PasswordChangedPage />
        },
        {
          path: '/:slug',
          element: <EventPage />
        },
        {
          path: '/:slug/:tab',
          element: <EventPage />
        }
      ]
    }
  ];

  // // Define routes accessible only to non-authenticated users
  // const routesForNotAuthenticatedOnly = [
  //   {
  //     path: '/login',
  //     element: <LoginPage />
  //   }
  // ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([...routes]);

  return <RouterProvider router={router} />;
};

export default MainRouter;
