import React from 'react';
import {Grid} from '@mui/material';
import {Link} from 'react-router-dom';

const PasswordChangedPage = () => {
  return (
    <Grid container justifyContent="center">
      Your password has been changed! Please <Link to="/login">Log In</Link>!
    </Grid>
  );
};

export default PasswordChangedPage;
