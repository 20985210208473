import React from 'react';
import {gql, useQuery} from '@apollo/client';

import {useParams} from 'react-router';
import {CircularProgress} from '@mui/material';

const ACTIVATE_ACCOUNT = gql`
  query ActivateAccount($str: String!) {
    activateAccount(str: $str) {
      firstName
    }
  }
`;

const ActivateAccount = () => {
  const {str} = useParams();

  const {loading, error} = useQuery(ACTIVATE_ACCOUNT, {
    variables: {
      str
    }
  });

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return null;
  }
  return (
    <React.Fragment>
      <h2>Thank You for activate your account</h2>
      <p>You can login now.</p>
    </React.Fragment>
  );
};

export default ActivateAccount;
