import React from 'react';
import {Grid} from '@mui/material';

const RegisteredPage = () => {
  return (
    <Grid container justifyContent="center">
      Thank You for signing up for LiveZone <br />
      Please activate your account with link in an email, which You will shortly receive.
    </Grid>
  );
};

export default RegisteredPage;
